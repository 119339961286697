import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


const dernieresOeilladesPreface = () => (
  <Layout>
    <SEO title="Dernières oeillades Preface" />
    <h2 className='underline-title'>Préface</h2>
    <br /><br />
    <div className="table-responsive">
      <p className="properly-aligned">
        <span className="par-starting">Nous</span> n'avons pas lu comme dans un miroir ce recueil de poèmes à la fois ouvert et fermé dans lequel Naté Ngu-Mongala
         nous envoie vivre "sa dernière" (?) oeuvre littéraire au Kivu. La raison pour cela est bien simple: Naté est poète. Il écrit, comme nous le fait comprendre
         Kanika Mwana-Ngombo dans une critique ouverte à l'occassion de la célébration, en février 1978, du mois de l'histoire afro-américaine, pour les seuls esprits
         alertes et dotés d'un sens très élevé d'imagination. 
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Nous</span> sommes en fait au sommet d'une pyramide dont la hauteur indique le long chemin parcouru par un homme dont le génie créateur
        n'est plus à démontrer. Comme l'avait souligné un jour le Professeur V.Y Mudimbe dans une correspondance addressée à l'auteur, <span className="black-bold">"Naté est ivre des mots qui lui pendent
        aux lèvres et à l'esprit exprimant haut ce que d'autres disent bas"</span>. Et c'est justement cette ivresse qu'il désire partager avec ceux qui veulent se laisser entraîner par le courant vivifiant de sa pensée.        
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Que</span> dire enfin du style employé dans <span className="black-bold">"Dernières Oeillades"</span> en dehors de ce dont sont convaincus Jacques Howlet de Présence Africaine 
        qui affirme que les poèmes et les textes en prose de Naté témoignent d'une sensibilité originale et de réelles qualités d'écritures; Mutombo Bombo de la Revue TOPAFRICA qui
        leur trouve une certaine recherche et une impénétrabilité quelque peu désarçonnante; Luzayamo Manteke du Journal Elima qui trouve que Naté écrit plus avec son coeur qu'avec
        sa plume?
      </p>
      <p className="properly-aligned">
        <span className="par-starting">Rien,</span> en effet, car tout a été dit.
      </p> 
      <p className="properly-aligned">
        <span className="par-starting">De</span> par leur présente édition et leurs nombreuses réeditions que nous souhaitons voir ses réaliser dans un avenir rapproché,  <span className="black-bold">"Dernières Oeillades"</span>
        s'immortalise désormais. Cette oeuvre d'une haute valeur intellectuelle sera toujours considérée par des milliers de critiques littéraires et par les admirateurs proches et lointains
        de Naté comme une précieuse source d'inspiration, comme une rélique, et enfin comme la voix d'un ami sincère qui continue à les interpeller.        
      </p>
      <p className="font-italic">
        Bamporiki Chamira<br/>
        Rédacteur en Chef du Journal JUA<br />
      </p>
    </div> 
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default dernieresOeilladesPreface
